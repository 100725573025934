import { ReactiveInjectable, injectable } from 'src/features/ioc';
import type { AggregatedPositionsWidgetModel } from 'src/models/AggregatedPositionsWidgetModel';

@injectable()
export class AggregatedPositionsWidgetModelImpl extends ReactiveInjectable implements AggregatedPositionsWidgetModel {
    constructor() {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }
}
