import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { AccountsWidgetModel } from 'src/models/AccountsWidgetModel';
import { userDoubleClickedAccount } from 'src/store/actions/widgets/accounts';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class AccountsWidgetModelImpl extends ReactiveInjectable implements AccountsWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    selectAccount(accountId: string) {
        this.store.dispatch(userDoubleClickedAccount(this.widgetData.tabId, accountId));
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }
}
