import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { PositionsWidgetModel } from 'src/models/PositionsWidgetModel';
import { updateWidget } from 'src/store/actions/widgetAndChannel';
import { userDoubleClickedPosition } from 'src/store/actions/widgets/positions';
import type { PositionsWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class PositionsWidgetModelImpl extends ReactiveInjectable implements PositionsWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') @reacts private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    private get widget() {
        return this.widgetData.widget as PositionsWidgetViewModel;
    }

    get symbolFilter() {
        return this.widget.symbolFilter;
    }

    update(updates: Partial<PositionsWidgetViewModel & { quantity?: number; tickerFilter?: string }>) {
        this.store.dispatch(
            updateWidget({
                tabId: this.widgetData.tabId,
                widgetUpdates: updates,
            }),
        );
    }

    selectPosition(symbol?: string, accountId?: string, quantity?: number) {
        this.store.dispatch(userDoubleClickedPosition(this.widgetData.tabId, symbol, accountId, quantity));
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }
}
