import type { AddedCriteria } from '../types';
import type { Universe } from 'src/contracts/universe';

export const SCREENER_CHANGE_EVENT = 'search-change';

export type ScreenerChangeEvent = CustomEvent<void>;

interface ScreenerModelDTOState {
    criteria: AddedCriteria[];
    excludeList: string[];
    includeList: string[];
}

export class ScreenerModelDTO {
    get excludeList() {
        return this.#state.excludeList;
    }

    get includeList() {
        return this.#state.includeList;
    }

    get criteria() {
        return this.#state.criteria;
    }

    changeTarget = new EventTarget();

    constructor() {
        this.#state = {
            criteria: [],
            excludeList: [],
            includeList: [],
        };
    }

    setCriteria(criteriaOrFn: AddedCriteria[] | ((currentCriteria: AddedCriteria[]) => AddedCriteria[])) {
        const newCriteria = typeof criteriaOrFn === 'function' ? criteriaOrFn(this.#state.criteria) : criteriaOrFn;

        this.state = {
            ...this.#state,
            criteria: newCriteria,
        };
    }

    setExcludeList(listOrFn: string[] | ((currentList: string[]) => string[])) {
        const newList = typeof listOrFn === 'function' ? listOrFn(this.#state.excludeList) : listOrFn;

        this.state = {
            ...this.#state,
            excludeList: newList,
        };
    }

    setIncludeList(listOrFn: string[] | ((currentList: string[]) => string[])) {
        const newList = typeof listOrFn === 'function' ? listOrFn(this.#state.includeList) : listOrFn;

        this.state = {
            ...this.#state,
            includeList: newList,
        };
    }

    #state: ScreenerModelDTOState;

    private set state(newState: ScreenerModelDTOState) {
        this.#state = newState;
        this.changeTarget.dispatchEvent(new CustomEvent(SCREENER_CHANGE_EVENT) satisfies ScreenerChangeEvent);
    }

    toUniverse(): Universe {
        return {
            aspects: [
                ...this.#state.criteria.map((criteria) => ({
                    type: 'formula' as const,
                    enabled: true,
                    action: 'include' as const,
                    formula: '', // TODO sync with David, create Criteria DTO with toFormula implementation
                    imports: [],
                    name: criteria.name || '',
                    description: '',
                })),
            ],
            inclusionList: this.#state.includeList,
            exclusionList: this.#state.excludeList,
            isTemplate: false,
        };
    }
}
