import { QueryObserverModel } from '../QueryObserverModel';
import { QueryObserverOptions } from '@tanstack/query-core';
import { inject, injectable, ReactiveInjectable, reacts } from 'src/features/ioc';
import { LibraryInitOptions, LibraryQueryResult, type LibraryModel } from 'src/models/LibraryModel';
import { ReactBindings } from 'src/types/bindings';

interface LibraryModelState<T> {
    error: Error | undefined;
    isFetching: boolean;
    isLoading: boolean;
    rows: T[];
    total: number;
}

@injectable()
export class LibraryModelImpl<T> extends ReactiveInjectable implements LibraryModel<T> {
    constructor(
        @inject('QueryObserverModelProvider')
        private queryObserverModelProvider: ReactBindings['QueryObserverModelProvider'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    init({ queryOptions }: LibraryInitOptions<T>) {
        this.#queryObserverModel = this.queryObserverModelProvider(queryOptions);

        const disposable = this.#queryObserverModel.subscribe((result) => {
            this.state = {
                error: result.error ?? undefined,
                isFetching: result.isFetching,
                isLoading: result.isLoading,
                rows: result.data?.rows ?? [],
                total: result.data?.total ?? 0,
            };
        });

        this.disposableStack.use(disposable);
    }

    get isFetching() {
        return this.#state.isFetching;
    }

    get isLoading() {
        return this.#state.isLoading;
    }

    get rows() {
        return this.#state.rows;
    }

    #queryObserverModel!: QueryObserverModel<LibraryQueryResult<T>>;
    setQueryOptions(queryOptions: QueryObserverOptions<LibraryQueryResult<T>>) {
        this.#queryObserverModel.setQueryOptions(queryOptions);
    }

    #state: LibraryModelState<T> = {
        error: undefined,
        isFetching: false,
        isLoading: true,
        rows: [],
        total: 0,
    };

    @reacts set state(state: LibraryModelState<T>) {
        this.#state = state;
    }

    get total() {
        return this.#state.total;
    }
}
