import type { NewLocateOrder } from 'src/contracts/locates/orders';
import type { LocatesWidgetModel as LocatesWidgetViewModel } from 'src/contracts/workspace';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { LocatesWidgetModel } from 'src/models/LocatesWidgetModel';
import type { LocatesWidgetState } from 'src/routes/widgets/LocatesWidget/hooks/useLocatesWidgetState';
import { createLocatesOrder } from 'src/store/actions/locates/locates';
import { updateWidget } from 'src/store/actions/widgetAndChannel';
import { userSelectedNewLocatesSymbol } from 'src/store/actions/widgets/locates';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class LocatesWidgetModelImpl extends ReactiveInjectable implements LocatesWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    private get widget() {
        return this.widgetData.widget as LocatesWidgetViewModel;
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }

    get isQuantityKilo() {
        return this.widget.isQuantityKilo;
    }

    sendLocatesRequest(order: Omit<NewLocateOrder, 'optimisticOrderId'>, autoAcceptPrice?: number) {
        // handle isQuantityKilo
        if (this.isQuantityKilo) {
            order.quantity = order.quantity * 1000;
        }

        this.store.dispatch(createLocatesOrder(order, autoAcceptPrice));
    }

    update(updates: Partial<LocatesWidgetState>) {
        const { tabId } = this.widgetData;

        this.store.dispatch(
            updateWidget({
                tabId,
                widgetUpdates: updates,
            }),
        );
    }

    updateFormData(formData: LocatesWidgetState['formData']) {
        this.update({ formData });
    }

    updateSymbol(symbol: string) {
        this.store.dispatch(userSelectedNewLocatesSymbol(this.widgetData.tabId, symbol));
    }

    userSelectedSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    get quickSendButtons() {
        return this.widget.quickSendButtons;
    }

    set quickSendButtons(buttons) {
        this.update({ quickSendButtons: buttons });
    }
}
