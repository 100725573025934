import type { AppAction } from '../actions';
import { allFlags } from 'src/lib/featureFlags/allFlags';

export interface FeatureFlagState {
    flags: Record<string, unknown>;
}

export const initialState: FeatureFlagState = {
    flags: Object.fromEntries(Object.values(allFlags).map((flag) => [flag.key, flag.defaultValue] as const)),
};

export const featureFlags = (state = initialState, action: AppAction): FeatureFlagState => {
    switch (action.type) {
        case 'featureFlags::set': {
            return { ...state, flags: { ...state.flags, ...action.flags } };
        }

        default:
            return state;
    }
};
