import { useUser } from './state';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useIsSuperuserEnabled } from 'src/components/authz/RequireSuperuserAuth';
import { makeUserQueryKey } from 'src/lib/config/query-client';

export const useUserQuery: typeof useQuery = (options, ...params) => {
    const user = useUser();
    const isSuperuserEnabled = useIsSuperuserEnabled();
    const userQueryKey = makeUserQueryKey(user?.id, isSuperuserEnabled);

    return useQuery(
        {
            ...options,
            queryKey: [...(options.queryKey as any), userQueryKey] as any,
        },
        ...params,
    ) as any;
};

export const useUserQueries: typeof useQueries = (options, ...params) => {
    const user = useUser();
    const isSuperuserEnabled = useIsSuperuserEnabled();
    const userQueryKey = makeUserQueryKey(user?.id, isSuperuserEnabled);

    const args = [
        {
            ...options,
            queries: options.queries.map((option) => ({
                ...option,
                ...('queryKey' in option ? { queryKey: [...(option.queryKey as any[]), userQueryKey] } : {}),
            })) as any, // please hold your nose
        },
        ...params,
    ] as const;

    return useQueries(...args) as any;
};
