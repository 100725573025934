import {
    type SearchCondition,
    CONDITION_CHANGE_EVENT,
    type ConditionChangeEvent,
    type SearchConditionGroup,
    type ConditionState,
    type SearchConditionErrorLocation,
} from '../../types';
import type { ConditionGroupModel } from '../ConditionGroupModel';
import { conditionStateToGuided, conditionStateToNonGuided } from './conditionState';
import { type ComparisonOperator } from '@thinkalpha/language-services';
import {
    hasErrors,
    isEmptyNaturalLanguageAtomState,
    isPendingNlpNaturalLanguageAtomState,
} from 'src/features/nlp/services/NLPEditorService/naturalLanguageAtomState';
import type { NaturalLanguageAtomState } from 'src/features/nlp/types/naturalLanguageAtomState';

export interface ConditionModelOptions {
    id: string;
    isSelfEnabled: boolean;
    parent: ConditionGroupModel;
    conditionState: ConditionState;
}

interface ConditionModelState {
    conditionState: ConditionState;
    id: string;
    isDirty: boolean;
    isSelfEnabled: boolean;
    isUnsaved: boolean;
    needsAttention: boolean;
}

// TBD: Implement change methods
export class ConditionModel implements SearchCondition {
    changeTarget = new EventTarget();

    #errorsCache = new WeakMap<ConditionState, SearchConditionErrorLocation[] | null>();

    #parent: ConditionGroupModel;

    #state: ConditionModelState;

    get conditionState() {
        return this.#state.conditionState;
    }

    constructor({ id, isSelfEnabled, parent, conditionState }: ConditionModelOptions) {
        this.#parent = parent;

        this.#state = {
            conditionState,
            id,
            isDirty: false,
            isSelfEnabled,
            isUnsaved: false,
            needsAttention: false,
        };
    }

    delete() {
        this.parent.removeChild(this);
    }

    get errors() {
        const { conditionState } = this.#state;

        // We lazily derive from state, but we can avoid re-computing on every render
        const cachedErrors = this.#errorsCache.get(conditionState);
        if (cachedErrors !== undefined) {
            return cachedErrors;
        }

        function getErrors(): SearchConditionErrorLocation[] | null {
            if (conditionState.type === 'guided') {
                const errors = [
                    hasErrors(conditionState.lhs) ? 'lhs' : null,
                    hasErrors(conditionState.rhs) ? 'rhs' : null,
                    conditionState.operator === null ? 'operator' : null,
                ].filter((error): error is SearchConditionErrorLocation => error !== null);

                if (errors.length) {
                    return errors;
                }

                return null;
            }

            if (hasErrors(conditionState.state)) {
                return ['line' as const];
            }

            return null;
        }

        const errors = getErrors();
        this.#errorsCache.set(conditionState, errors);
        return errors;
    }

    get id() {
        return this.#state.id;
    }

    get isDirty() {
        return this.#state.isDirty;
    }

    get isEmpty() {
        if (this.conditionState.type === 'non-guided') {
            return isEmptyNaturalLanguageAtomState(this.conditionState.state);
        } else if (this.conditionState.type === 'guided') {
            return (
                isEmptyNaturalLanguageAtomState(this.conditionState.lhs) &&
                isEmptyNaturalLanguageAtomState(this.conditionState.rhs)
            );
        }

        return false;
    }

    get isEnabledInSearch() {
        return this.#state.isSelfEnabled && this.#parent.isEnabledInSearch;
    }

    get isGuidedMode() {
        return this.#state.conditionState.type === 'guided';
    }

    get isPendingNlp() {
        if (!this.isEnabledInSearch) {
            return false;
        }

        if (this.conditionState.type === 'non-guided') {
            return isPendingNlpNaturalLanguageAtomState(this.conditionState.state);
        } else if (this.conditionState.type === 'guided') {
            return (
                isPendingNlpNaturalLanguageAtomState(this.conditionState.lhs) ||
                isPendingNlpNaturalLanguageAtomState(this.conditionState.rhs)
            );
        }

        return false;
    }

    get isSelfEnabled() {
        return this.#state.isSelfEnabled;
    }

    get isUnsaved() {
        return this.#state.isUnsaved;
    }

    get isUnsubmitted() {
        return this.#state.isDirty;
    }

    get needsAttention() {
        return this.#state.needsAttention;
    }

    set needsAttention(needsAttention: boolean) {
        this.state = {
            ...this.#state,
            needsAttention,
        };
    }

    get parent() {
        return this.#parent;
    }

    setEnabled(isEnabled: boolean): void {
        // TODO: Let parent derive this better
        this.#parent.setIsDirty(true);
        this.#parent.setIsUnsaved(true);
        this.state = {
            ...this.#state,
            isDirty: isEnabled ? true : this.#state.isDirty,
            needsAttention: isEnabled ? this.#state.needsAttention : false,
            isSelfEnabled: isEnabled,
            isUnsaved: true,
        };
    }

    setGuidedMode(isGuidedMode: boolean): void {
        if (isGuidedMode && this.#state.conditionState.type === 'non-guided') {
            const groupOrLine = conditionStateToGuided(this.#state.conditionState, this.#parent);
            if (groupOrLine instanceof ConditionModel) {
                this.state = {
                    ...this.#state,
                    isDirty: true,
                    isUnsaved: true,
                    conditionState: groupOrLine.conditionState,
                };
            }

            this.#parent.replaceChild(this, groupOrLine);
        } else if (!isGuidedMode && this.#state.conditionState.type === 'guided') {
            this.state = {
                ...this.#state,
                isDirty: true,
                isUnsaved: true,
                conditionState: conditionStateToNonGuided(this.#state.conditionState),
            };
        }
    }

    setIsDirty(isDirty: boolean): void {
        this.state = { ...this.#state, isDirty };
    }

    setIsUnsaved(isUnsaved: boolean): void {
        this.state = { ...this.#state, isUnsaved };
    }

    setParent(parent: SearchConditionGroup): void {
        this.#parent = parent as ConditionGroupModel;
    }

    private set state(newState: ConditionModelState) {
        this.#state = newState;
        this.changeTarget.dispatchEvent(new CustomEvent(CONDITION_CHANGE_EVENT) satisfies ConditionChangeEvent);
    }

    updateConditionState(state: NaturalLanguageAtomState, side?: 'lhs' | 'rhs') {
        if (side && this.#state.conditionState.type === 'guided') {
            let isDirty = true;
            if (this.#state.conditionState[side].atom.status === 'user_code' && state.atom.status === 'user_code') {
                isDirty = this.#state.isDirty || this.#state.conditionState[side].atom.code !== state.atom.code;
            }

            const guidedConditionState = {
                ...this.#state.conditionState,
                [side]: state,
            };

            this.state = {
                ...this.#state,
                isDirty,
                isUnsaved: isDirty,
                conditionState: guidedConditionState,
            };

            return;
        }

        let isDirty = true;
        if (
            this.#state.conditionState.type === 'non-guided' &&
            this.#state.conditionState.state.atom.status === 'user_code' &&
            state.atom.status === 'user_code'
        ) {
            isDirty = this.#state.isDirty || this.#state.conditionState.state.atom.code !== state.atom.code;
        }

        this.state = {
            ...this.#state,
            isDirty,
            isUnsaved: isDirty,
            conditionState: {
                state,
                type: 'non-guided',
            },
        };
    }

    updateConditionOperator(operator: ComparisonOperator) {
        if (this.#state.conditionState.type === 'guided') {
            const guidedConditionState = {
                ...this.#state.conditionState,
                operator,
            };

            this.state = {
                ...this.#state,
                isDirty: true,
                isUnsaved: true,
                conditionState: guidedConditionState,
            };
        }
    }
}
