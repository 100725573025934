import type { NewsStory, NewsQuery, FullNewsStory, TradingHours } from './contracts';
import { type ZoneId } from '@js-joda/core';
import type { Interval } from '@js-joda/extra';
import { QuantClient } from '@thinkalpha/quant-ws-client/client.js';
import { createInstance } from 'src/api';
import { type Logo } from 'src/contracts/logo';
import { appConfig } from 'src/lib/config';

const axios = createInstance({ baseURL: appConfig.newQuantApi });
const quantWsClient = new QuantClient(axios);

export async function getBulkNewsBySymbol(payload: NewsQuery): Promise<NewsStory[]> {
    const resp = await axios.post('/news/queries', payload);
    return resp.data;
}

export async function getFullArticleContent(articleId: string): Promise<FullNewsStory> {
    const resp = await axios.get(`/news/story/${articleId}`);
    return resp.data;
}

export function getCompanyLogoFromSymbol(symbol: string): Promise<Logo> {
    return axios.get(`/logos/by-symbol/${symbol}`).then((res) => res.data);
}

export async function getTradingHoursForMicCode(mic: string, timeframe: Interval): Promise<TradingHours> {
    return quantWsClient.getTradingHoursForMarket(mic, timeframe);
}

export async function getTimezoneForMicCode(mic: string): Promise<ZoneId> {
    return quantWsClient.getTimezoneOfMarket(mic);
}
