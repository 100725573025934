import type { NaturalLanguageAtomState, NaturalLanguageDisplayMode } from '../../types/naturalLanguageAtomState';
import type { CodeEditorResult } from 'src/features/code-editor';
import type { ReactiveInjectable } from 'src/features/ioc';
import type { NlpQueryType, NlpSuggestion } from 'src/lib/nlp/contracts';
import type { ImportsManagerModel } from 'src/models/ImportsManagerModel';

export interface NLPEditorServiceInitOptions {
    initialState: NaturalLanguageAtomState;
    importsManager: ImportsManagerModel;
    queryType?: NlpQueryType;
}

interface NLPEditorServiceFlow {
    confirmParsedResponse(): Promise<void>;
    editParsedResponse(): void;
    isSubmitting: boolean;
    rejectParsedResponse(): void;
    submit(): Promise<void>;
}

interface NLPEditorServiceModes {
    setMode(mode: NaturalLanguageDisplayMode): void;
    setQueryType(queryType: NlpQueryType): void;
}

interface NLPEditorServiceWriting {
    codeChange(result: CodeEditorResult): void;
    naturalLanguageChange(naturalLanguage: string): void;
    acceptSuggestion(suggestion: NlpSuggestion): void;
}

export type NLPStateChangeEvent = CustomEvent<NaturalLanguageAtomState>;

export const NLP_STATE_CHANGE_EVENT = 'nlp-state-change';

export interface NLPEditorService
    extends ReactiveInjectable,
        NLPEditorServiceFlow,
        NLPEditorServiceModes,
        NLPEditorServiceWriting {
    nlpState: NaturalLanguageAtomState;

    stateChangeTarget: EventTarget;

    init(options: NLPEditorServiceInitOptions): void;
}
