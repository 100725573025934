import { IndicatorImportParamModel } from './IndicatorImportParamModel';
import type { ConcreteIndicatorViewModel, IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import { IndicatorImportRefModel } from 'src/dtos/IndicatorImportRef';
import type { DictionaryFunctionDef } from 'src/lib/dictionaryModel';
import { mapIndicatorToDictionaryFunctionDef } from 'src/lib/util/dictionary';

/*
 * TODO: Come up with a strategy for either
 * - handling all model creation via IOC
 * - or finding a way to distinguish IOC-created models from vanilla models
 */

/**
 * Indicator imports are always fully "materialized"
 */
export class IndicatorImportModel {
    #alias: string;

    /**
     * Underlying indicator POJO
     */
    #concreteIndicator: ConcreteIndicatorViewModel;

    #params: IndicatorImportParamModel[];

    get alias(): string {
        return this.#alias;
    }

    private constructor(alias: string, concreteIndicator: ConcreteIndicatorViewModel) {
        this.#alias = alias;
        this.#concreteIndicator = concreteIndicator;
        this.#params = [];
        for (const param of concreteIndicator.params) {
            this.#params.push(new IndicatorImportParamModel(param));
        }
    }

    /**
     * @deprecated Prefer to create individual getters for each property of the concrete indicator
     */
    get concreteIndicator(): ConcreteIndicatorViewModel {
        return this.#concreteIndicator;
    }

    static fromIndicatorImportViewModel({
        alias,
        ...concreteIndicator
    }: IndicatorImportViewModel): IndicatorImportModel {
        return new IndicatorImportModel(alias, concreteIndicator);
    }

    get id(): string {
        return this.#concreteIndicator.id;
    }

    get isCustomAliased(): boolean {
        return this.#alias !== this.#concreteIndicator.symbol;
    }

    get numRequiredParams() {
        return this.#concreteIndicator.params.filter((i) => !i.optional).length ?? 0;
    }

    get params() {
        return this.#params;
    }

    /**
     * @deprecated This is to maintain compatibility with the old analyzer format.
     *
     * It would be nice to instead have an analyzer definition which can work directly with the model.
     */
    toDictionaryFunctionDef(): DictionaryFunctionDef {
        return mapIndicatorToDictionaryFunctionDef(this.#concreteIndicator);
    }

    toRef(): IndicatorImportRefModel {
        return IndicatorImportRefModel.fromIndicatorImportRefViewModel({
            alias: this.#alias,
            id: this.#concreteIndicator.id,
            version: this.#concreteIndicator.version,
        });
    }

    get version(): number {
        return this.#concreteIndicator.version;
    }
}
