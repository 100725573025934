import { PaginationState } from '@tanstack/react-table';
import { injectable, ReactiveInjectable, reacts } from 'src/features/ioc';
import type { PaginationDataModel } from 'src/models/PaginationDataModel';

interface PaginationImplState {
    pageIndex: number;
    pageSize: number;
}

@injectable()
export class PaginationDataModelImpl extends ReactiveInjectable implements PaginationDataModel {
    constructor() {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    #state: PaginationImplState = {
        pageIndex: 0,
        pageSize: 10,
    };

    get pageIndex(): number {
        return this.#state.pageIndex;
    }

    get pageSize(): number {
        return this.#state.pageSize;
    }

    @reacts set state(state: PaginationImplState) {
        this.#state = state;
    }

    updatePagination(newValues: PaginationState) {
        const { pageIndex, pageSize } = newValues;

        this.state = {
            ...this.#state,
            pageIndex,
            pageSize,
        };
    }
}
