export type SetScreenerDisplayingResultsAction = {
    type: 'setScreenerDisplayingResults';
    tabId: string;
    isShowing: boolean;
};
export type SetScreenerDisplayingBuilderAction = {
    type: 'setScreenerDisplayingBuilder';
    tabId: string;
    isShowing: boolean;
};

export type StoreScreenerSplitPercentageAction = {
    type: 'storeScreenerSplitPercentage';
    tabId: string;
    splitPercentage: number;
};

export const setScreenerDisplayingBuilder = (
    tabId: string,
    isShowing: boolean,
): SetScreenerDisplayingBuilderAction => ({
    tabId,
    isShowing,
    type: 'setScreenerDisplayingBuilder',
});
export const setScreenerDisplayingResults = (
    tabId: string,
    isShowing: boolean,
): SetScreenerDisplayingResultsAction => ({
    tabId,
    isShowing,
    type: 'setScreenerDisplayingResults',
});
export const storeScreenerSplitPercentage = (
    tabId: string,
    splitPercentage: number,
): StoreScreenerSplitPercentageAction => ({
    tabId,
    splitPercentage,
    type: 'storeScreenerSplitPercentage',
});

export type ScreenerActions =
    | SetScreenerDisplayingBuilderAction
    | SetScreenerDisplayingResultsAction
    | StoreScreenerSplitPercentageAction;
