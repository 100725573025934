import { FeatureFlag } from './FeatureFlag';

const modules = import.meta.glob('./flags/*.ts', { eager: true }) as Record<
    /* path */ string,
    /* module */ Record<string, unknown>
>;

type AllFlags = Record</* key */ string, /* value */ FeatureFlag<unknown>>;

export const allFlags: AllFlags = Object.entries(modules).reduce((flags, [_path, module]) => {
    if (!module) return flags;
    for (const exportName in module) {
        if (module[exportName] instanceof FeatureFlag) {
            flags[module[exportName].key] = module[exportName];
        }
    }
    return flags;
}, {} as AllFlags);
