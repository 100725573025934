// import type { StrategyRecord } from 'src/contracts/strategy';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { AskAlphaListWidgetModel } from 'src/models/AskAlphaListWidgetModel';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class AskAlphaListWidgetModelImpl extends ReactiveInjectable implements AskAlphaListWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }

    userSelectedSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }
}
