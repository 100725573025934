import type { CustomElement } from '../../slate';
import type { CallNode } from '@thinkalpha/language-services';
import type { IndicatorImportModel } from 'src/dtos/IndicatorImport';

export interface CallNodeDraftState {
    // The slate editor children before the draft started
    slateEditorChildrenSnapshot: CustomElement[];
    // The call node being modified
    originalCallNode: CallNode;
    // The indicator referenced by the CallNode
    indicatorImport: IndicatorImportModel;
    // Replacement call string
    replacementCallString: string;
    // The params at the time the input helper was shown
    draftParams: string[];
}

const makeReplacementCallString = (indicatorImport: IndicatorImportModel, draftParams: string[]): string => {
    const params = draftParams.join(',').replace(/,+$/, '');
    return `${indicatorImport.alias}(${params})`;
};

export const createCallNodeDraftState = (
    indicatorImport: IndicatorImportModel,
    originalCallNode: CallNode,
    slateEditorChildrenSnapshot: CustomElement[],
): CallNodeDraftState => {
    // TODO: Derive draftParams from originalCallNode
    const draftParams: string[] = [];

    const replacementCallString = makeReplacementCallString(indicatorImport, draftParams);

    return {
        indicatorImport,
        slateEditorChildrenSnapshot,
        originalCallNode,
        replacementCallString,
        draftParams,
    };
};

export const updateCallNodeDraftStateParams = (
    state: CallNodeDraftState,
    draftParams: string[],
): CallNodeDraftState => {
    const replacementCallString = makeReplacementCallString(state.indicatorImport, draftParams);

    return {
        ...state,
        replacementCallString,
        draftParams,
    };
};
