import type { NaturalLanguageAtomState } from '../../types/naturalLanguageAtomState';
import { IndicatorImportRefModel } from 'src/dtos/IndicatorImportRef';
import type { CodeEditorResult } from 'src/features/code-editor';
import type { CodeEditorError } from 'src/features/code-editor/services/CodeEditorService/interface';
import type { NlpParseResponseData, NlpParseResponseError, NlpSuggestion } from 'src/lib/nlp/schema';

export const handleNaturalLanguageChange = (
    state: NaturalLanguageAtomState,
    naturalLanguage: string,
): NaturalLanguageAtomState => {
    const nextStateAtom: NaturalLanguageAtomState['atom'] = {
        status: 'writing' as const,
    };

    if (state.atom.status === 'confirmed') {
        nextStateAtom.previouslyConfirmedFormula = state.atom.confirmedFormula;
    }

    return {
        naturalLanguage,
        displayMode: state.displayMode,
        atom: nextStateAtom,
    };
};

export const handleAcceptSuggestion = (
    state: NaturalLanguageAtomState,
    suggestion: NlpSuggestion,
): NaturalLanguageAtomState => {
    return handleNaturalLanguageChange(state, suggestion.input);
};

export const handleCodeChange = (
    state: NaturalLanguageAtomState,
    result: CodeEditorResult,
): NaturalLanguageAtomState => {
    if (result.code === '' && state.atom.status !== 'user_code') {
        return state;
    }

    if (state.atom.status === 'confirmed' && result.code === state.atom.confirmedFormula) {
        return state;
    }

    return {
        naturalLanguage: '',
        displayMode: state.displayMode,
        atom: {
            status: 'user_code' as const,
            errors: result.errors,
            result: result.result,
            code: result.code,
            previousNaturalLanguageAtomState:
                state.atom.status === 'user_code' ? state.atom.previousNaturalLanguageAtomState : state,
        },
    };
};

export const handleSetMode = (
    state: NaturalLanguageAtomState,
    displayMode: NaturalLanguageAtomState['displayMode'],
): NaturalLanguageAtomState => {
    return {
        ...state,
        displayMode,
    };
};

export const hasErrors = (state: NaturalLanguageAtomState) => {
    if (state.atom.status === 'errored') {
        return true;
    }

    if (state.atom.status === 'user_code' && state.atom.code !== '' && Boolean(state.atom.errors?.length)) {
        return true;
    }

    return false;
};

export const handleParsing = (state: NaturalLanguageAtomState): NaturalLanguageAtomState => {
    return {
        ...state,
        atom: {
            status: 'parsing' as const,
        },
    };
};

export const handleParsedResponse = (
    state: NaturalLanguageAtomState,
    response: NlpParseResponseData,
): NaturalLanguageAtomState => {
    return {
        ...state,
        atom: {
            status: 'parsed' as const,
            unconfirmedFormula: {
                formula: response.result.formula,
                aliases: response.result.imports.map((ref) =>
                    IndicatorImportRefModel.fromIndicatorImportRefViewModel(ref),
                ),
            },
        },
    };
};

export const handleParsedResponseError = (
    state: NaturalLanguageAtomState,
    error: NlpParseResponseError,
): NaturalLanguageAtomState => {
    return {
        ...state,
        atom: {
            status: 'errored' as const,
            suggestions: error.detail.similar ?? [],
        },
    };
};

export const handleRejectParsedResponse = (state: NaturalLanguageAtomState): NaturalLanguageAtomState => {
    return {
        ...state,
        atom: {
            status: 'writing' as const,
        },
    };
};

export const handleEditParsedResponse = (state: NaturalLanguageAtomState): NaturalLanguageAtomState => {
    const { atom } = state;
    if (atom.status === 'parsed') {
        return {
            ...state,
            displayMode: 'formula',
            atom: {
                status: 'user_code' as const,
                code: atom.unconfirmedFormula.formula,
                previousNaturalLanguageAtomState: state,
            },
        };
    }

    return state;
};

export const handleConfirmParsedResponse = (
    state: NaturalLanguageAtomState,
    confirmedFormula: string,
    errors?: CodeEditorError[],
): NaturalLanguageAtomState => {
    const { atom } = state;
    if (atom.status === 'parsed') {
        // If there are errors after the user confirms, we kick them out of 'confirmed' status into 'user_code' status.
        if (errors?.length) {
            return {
                ...state,
                atom: {
                    status: 'user_code' as const,
                    code: atom.unconfirmedFormula.formula,
                    // Give the user the ability to go back to the 'writing' state with their NLP to edit and re-submit
                    previousNaturalLanguageAtomState: {
                        displayMode: 'language',
                        naturalLanguage: state.naturalLanguage,
                        atom: {
                            status: 'writing' as const,
                        },
                    },
                },
                displayMode: 'formula',
            };
        }

        return {
            ...state,
            displayMode: errors ? 'formula' : 'language',
            atom: {
                status: 'confirmed' as const,
                confirmedFormula,
            },
        };
    }

    return state;
};

export const isEmptyNaturalLanguageAtomState = (state: NaturalLanguageAtomState): boolean => {
    if (state.atom.status === 'writing' && state.naturalLanguage === '') {
        return true;
    }

    if (state.atom.status === 'user_code' && state.atom.code === '') {
        return true;
    }

    return false;
};

export const isPendingNlpNaturalLanguageAtomState = (state: NaturalLanguageAtomState): boolean => {
    if (state.atom.status === 'writing' && state.naturalLanguage === '') {
        return false;
    }

    if (['user_code', 'confirmed'].includes(state.atom.status)) {
        return false;
    }

    return true;
};

/**
 * Get the runnable code string for a NaturalLanguageAtomState. This excludes unconfirmed formulas.
 */
export const getNaturalLanguageAtomStateCodeString = (state: NaturalLanguageAtomState): string | null => {
    if (state.atom.status === 'user_code') {
        return state.atom.code;
    }
    if (state.atom.status === 'confirmed') {
        return state.atom.confirmedFormula;
    }
    return null;
};
