import spinner from './loading.svg';
import styles from './spinner.module.scss';
import React, { useRef } from 'react';
import { cn } from 'src/lib/utils';

export interface SpinnerProps {
    size?: 'small' | 'medium' | 'large' | 'flexible';
    hidden?: boolean;
    className?: string | undefined;
    centered?: boolean;
}

type SizeClassNames = keyof Pick<
    typeof styles,
    'custom-spinner-large' | 'custom-spinner-medium' | 'custom-spinner-small' | 'customer-spinner-flexible'
>;

export const Spinner = ({ size = 'medium', hidden, className, centered = false }: SpinnerProps) => {
    const container = useRef<HTMLDivElement>(null);

    const sizeClassName = `custom-spinner-${size}` as SizeClassNames;

    return (
        <div
            className={cn(styles['custom-spinner'], className)}
            hidden={hidden}
            data-testid="wait-spinner"
            style={{ colorScheme: 'light' }}
        >
            <div
                className={cn(styles[sizeClassName], centered ? styles['custom-spinner-centered'] : ``)}
                ref={container}
            >
                <object type="image/svg+xml" data={spinner}>
                    <img src={spinner} />
                </object>
            </div>
        </div>
    );
};

export const SpinnerOverlay = (spinnerProps: SpinnerProps) => {
    return (
        <div className={cn(styles['custom-spinner-overlay'], 'bg-background')}>
            <Spinner centered {...spinnerProps} />
        </div>
    );
};
