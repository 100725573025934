export type UserDoubleClickedAccountFromTableAction = {
    type: 'userDoubleClickedAccountFromTable';
    accountId: string;
    tabId: string;
};

export const userDoubleClickedAccount = (
    tabId: string,
    accountId: string,
): UserDoubleClickedAccountFromTableAction => ({
    accountId,
    tabId,
    type: 'userDoubleClickedAccountFromTable',
});

export type AccountsActions = UserDoubleClickedAccountFromTableAction;
