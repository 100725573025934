import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { ScreenerModelDTO } from 'src/features/screener/dtos';
import { SCREENER_CHANGE_EVENT } from 'src/features/screener/dtos/ScreenerModelDTO';
import { ScreenerWidgetModel } from 'src/models/ScreenerWidgetModel';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import {
    setScreenerDisplayingBuilder,
    setScreenerDisplayingResults,
    storeScreenerSplitPercentage,
} from 'src/store/actions/widgets/screener';
import type { ScreenerWidgetViewModel } from 'src/store/types';
import { ReactBindings } from 'src/types/bindings';

@injectable()
export class ScreenerWidgetModelImpl extends ReactiveInjectable implements ScreenerWidgetModel {
    get columnTemplate() {
        return this.widget.columnTemplate;
    }

    constructor(
        @inject('ImportsManagerModel') @reacts public importsManager: ReactBindings['ImportsManagerModel'],
        @inject('Store') @reacts public store: ReactBindings['Store'],
        @inject('WidgetDataModel') @reacts public widgetData: ReactBindings['WidgetDataModel'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        // TODO implement a change target subscription class
        const listener = this.#onScreenerChange.bind(this);
        this.#screener.changeTarget.addEventListener(SCREENER_CHANGE_EVENT, listener);
        this.disposableStack.defer(() => {
            this.#screener.changeTarget.removeEventListener(SCREENER_CHANGE_EVENT, listener);
        });
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }

    get isShowingBuilder() {
        return this.widget.displayingBuilder;
    }

    set isShowingBuilder(value: boolean) {
        this.store.dispatch(setScreenerDisplayingBuilder(this.widgetData.tabId, value));
    }

    get isShowingResults() {
        return this.widget.displayingResults;
    }

    set isShowingResults(value: boolean) {
        this.store.dispatch(setScreenerDisplayingResults(this.widgetData.tabId, value));
    }

    #screener = new ScreenerModelDTO();

    #onScreenerChange() {
        this.rerender();
    }

    get screener() {
        return this.#screener;
    }

    get splitPercentage() {
        return this.widget.splitPercentage;
    }

    set splitPercentage(value: number) {
        this.store.dispatch(storeScreenerSplitPercentage(this.widgetData.tabId, value));
    }

    userSelectedSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    get widget() {
        return this.widgetData.widget as ScreenerWidgetViewModel;
    }
}
