import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { OrdersWidgetModel } from 'src/models/OrdersWidgetModel';
import { userDoubleClickedOrder } from 'src/store/actions/widgets/orders';
import type { OrdersWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class OrdersWidgetModelImpl extends ReactiveInjectable implements OrdersWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    private get widget() {
        return this.widgetData.widget as OrdersWidgetViewModel;
    }

    selectOrder(symbol?: string, accountId?: string) {
        this.store.dispatch(userDoubleClickedOrder(this.widgetData.tabId, symbol, accountId));
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }

    get symbolFilter() {
        return this.widget.symbolFilter;
    }
}
