import { QueryObserver, type UseQueryOptions } from '@tanstack/react-query';
import type { OutputColumnWithMetadata } from '@thinkalpha/platform-ws-client/contracts/table.js';
import { TableClient, type Bounds, type RowUpdate } from '@thinkalpha/table-client';
import {
    concat,
    connectable,
    distinctUntilChanged,
    filter,
    from,
    map,
    NEVER,
    of,
    ReplaySubject,
    switchMap,
    type Connectable,
    type Observable,
} from 'rxjs';
import { getSnapshot } from 'src/api/getTableSnapshot';
import type { TableStatus } from 'src/components/table-view/model';
import { inject, injectable, ReactiveInjectable, reacts } from 'src/features/ioc';
import type { TableCreationOptions, TableModel } from 'src/models/TableModel';
import type { FullKeyType } from 'src/types';
import type { ReactBindings } from 'src/types/bindings';

interface TableState {
    columns: OutputColumnWithMetadata[];
    error: Error | undefined;
    tableCreationResult: FullKeyType;
    tableClient: TableClient | null;
    tableStatus: TableStatus;
}

type NonNullFullKeyType = Exclude<FullKeyType, null>;

type TableStateAction =
    | { type: 'clearTableClient' }
    | { type: 'enterErroredState'; error: Error }
    | { type: 'updateTableConnectionStatus'; status: TableStatus }
    | {
          type: 'useNewTableCreationResult';
          tableCreationResult: NonNullFullKeyType;
          rawClient: ReactBindings['ProxyClient'];
      };

const DEFAULT_THROTTLE_MS = 1;

const tableStateReducer = (state: TableState, action: TableStateAction): TableState => {
    switch (action.type) {
        case 'clearTableClient':
            return {
                ...state,
                columns: [],
                error: undefined,
                tableClient: null,
                tableCreationResult: null,
            };
        case 'enterErroredState':
            return {
                ...state,
                columns: [],
                error: action.error,
                tableClient: null,
                tableCreationResult: null,
            };
        case 'useNewTableCreationResult': {
            const { rawClient, tableCreationResult } = action;
            const tableClient = new TableClient(rawClient, tableCreationResult.key);
            return {
                ...state,
                columns: tableCreationResult.columns,
                error: undefined,
                tableClient,
                tableCreationResult,
            };
        }
        case 'updateTableConnectionStatus':
            if (action.status === state.tableStatus) {
                return state;
            }
            return {
                ...state,
                tableStatus: action.status,
            };
        default:
            return state;
    }
};

const NEW_TABLE_RESET = Symbol('NEW_TABLE_RESET');

@injectable()
export class TableModelImpl extends ReactiveInjectable implements TableModel {
    #bounds!: Bounds;

    get bounds() {
        return this.#bounds;
    }

    #columnByIdMap = new Map<string, OutputColumnWithMetadata>();

    get columns() {
        return this.#state.columns;
    }

    #countConnected$: Connectable<number>;
    #count$: Observable<number>;

    get count$() {
        return this.#count$;
    }

    get connectionStatus() {
        return this.#state.tableStatus;
    }

    constructor(
        @inject('QueryClient') private queryClient: ReactBindings['QueryClient'],
        @inject('ProxyClient') private proxyClient: ReactBindings['ProxyClient'],
        @inject('Logger') private logger: ReactBindings['Logger'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.disposableStack.use(
            this.proxyClient.connectionStatus$.subscribe((status) => {
                this.#takeStateAction({ type: 'updateTableConnectionStatus', status });
            }),
        );

        {
            this.#updateConnected$ = connectable(
                from(this).pipe(
                    map(() => this.tableClient),
                    distinctUntilChanged(),
                    switchMap((tc) => concat(of(NEW_TABLE_RESET as unknown as RowUpdate), tc ? tc.update$ : NEVER)),
                ),
                { connector: () => new ReplaySubject(1) },
            );

            this.disposableStack.use(this.#updateConnected$.connect());

            this.#update$ = this.#updateConnected$.pipe(filter((update) => (update as any) !== NEW_TABLE_RESET));
        }

        {
            this.#countConnected$ = connectable(
                from(this).pipe(
                    map(() => this.tableClient),
                    distinctUntilChanged(),
                    switchMap((tc) => concat(of(NEW_TABLE_RESET as unknown as number), tc ? tc.rowCount$ : NEVER)),
                ),
                { connector: () => new ReplaySubject(1) },
            );
            this.disposableStack.use(this.#countConnected$.connect());

            this.#count$ = this.#countConnected$.pipe(filter((count) => (count as any) !== NEW_TABLE_RESET));
        }

        this.#log = logger.getSubLogger({ name: 'table:model' });
    }

    get error() {
        return this.#state.error;
    }

    getColumnById(columnId: string): OutputColumnWithMetadata | null {
        return this.#columnByIdMap.get(columnId) ?? null;
    }

    getColumnUpdate$(columnId: string) {
        // this.#log.trace({ message: 'TableClient is not null, returning update$', columnId });
        return this.update$.pipe(map((update) => update[columnId]));
    }

    init(tableCreationOptions: TableCreationOptions) {
        this.#query = new QueryObserver(this.queryClient, tableCreationOptions.queryOptions);

        // Arbitrary default value; every location in the product overrides this so it doesn't matter.
        this.#bounds = tableCreationOptions.initBounds || { firstRow: 0, windowSize: 100 };
        this.#throttle = tableCreationOptions.initThrottle;

        const unsubscribe = this.#query.subscribe((tableCreationResult) => {
            if (tableCreationResult.isPending) {
                return;
            }

            if (!tableCreationResult.isSuccess) {
                this.#log.error({
                    message: 'Table creation failed',
                    error: tableCreationResult.error ?? undefined,
                    tableCreationResult,
                });

                // Put table into error state which the UI can read
                this.#takeStateAction({
                    type: 'enterErroredState',
                    error: tableCreationResult.error ?? new Error('Unknown Table Creation error'),
                });
                return;
            }

            // How does this get reached? ... for now we'll re-use error state from above
            if (!tableCreationResult.data) {
                this.#log.error({
                    message: 'Table creation resulted in no data',
                    error: tableCreationResult.error ?? undefined,
                });

                this.#takeStateAction({ type: 'clearTableClient' });
                return;
            }

            if (
                // No client, create a new one
                !this.#state.tableClient ||
                // Recycle the existing client for a new one since the key has changed
                this.#state.tableClient.key !== tableCreationResult.data.key
            ) {
                this.#takeStateAction({
                    type: 'useNewTableCreationResult',
                    tableCreationResult: tableCreationResult.data,
                    rawClient: this.proxyClient,
                });

                const tc = this.#state.tableClient!;

                tc.bounds = this.#bounds;
                tc.throttle = this.#throttle === undefined ? DEFAULT_THROTTLE_MS : this.#throttle;

                return;
            }

            //this.#takeStateAction({ type: 'clearTableClient' });
        });
        this.disposableStack.defer(unsubscribe);

        this.disposableStack.defer(() => {
            if (this.#state.tableClient) {
                this.#state.tableClient[Symbol.dispose]();
            }
        });
    }

    get key() {
        return this.#state.tableClient?.key ?? null;
    }

    #log: ReactBindings['Logger'];

    #query: QueryObserver<FullKeyType> | undefined;

    // TODO: Allow bounds to be set to undefined
    setBounds(bounds: Bounds) {
        this.#bounds = bounds;
        if (this.#state.tableClient) {
            this.#state.tableClient.bounds = bounds;
        }
    }

    setThrottle(throttle: number | undefined): void {
        this.#throttle = throttle;
        if (this.#state.tableClient) {
            this.#state.tableClient.throttle = throttle === undefined ? DEFAULT_THROTTLE_MS : throttle;
        }
    }

    async snapshot({ firstRow, windowSize }: { firstRow?: number; windowSize?: number }) {
        if (this.#state.tableCreationResult === null) {
            return null;
        }

        const { key, tableCookie } = this.#state.tableCreationResult;

        if (!tableCookie) {
            return null;
        }

        return getSnapshot(key, tableCookie, firstRow, windowSize);
    }

    get sortable() {
        if (this.#state.tableCreationResult?.key) {
            return this.#state.tableCreationResult.key.ex !== 'X' && this.#state.tableCreationResult.key.ex !== 'M';
        }
        return false;
    }

    #state: TableState = {
        columns: [],
        error: undefined,
        tableClient: null,
        tableCreationResult: null,
        tableStatus: 'uninitialized',
    };

    private get state() {
        return this.#state;
    }

    @reacts private set state(state: TableState) {
        const previousState = this.#state;
        this.#state = state;

        // Sync the column lookup Map
        if (state.columns.length === 0) {
            this.#columnByIdMap.clear();
        } else if (state.columns !== previousState.columns) {
            this.#columnByIdMap = new Map(state.columns.map((column) => [column.id, column]));
        }

        if (previousState.tableClient !== state.tableClient) {
            if (previousState.tableClient) {
                previousState.tableClient[Symbol.dispose]();
            }
        }
    }

    get tableClient() {
        return this.#state.tableClient;
    }

    #takeStateAction(action: TableStateAction) {
        const nextState = tableStateReducer(this.state, action);
        if (nextState !== this.state) {
            this.state = nextState;
        }
    }

    #throttle: number | undefined;

    get throttle() {
        return this.#throttle;
    }

    #updateConnected$: Connectable<RowUpdate>;
    #update$: Observable<RowUpdate>;

    get update$(): Observable<RowUpdate> {
        return this.#update$;
    }

    updateQueryOptions(queryOptions: UseQueryOptions<FullKeyType>) {
        this.#query?.setOptions(queryOptions);
    }
}
