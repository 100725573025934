import { ConditionModel } from '.';
import type { ConditionGroupModel } from '../ConditionGroupModel';
import type { IfThenLine } from 'src/contracts/if-then';
import type { NaturalLanguageAtomState } from 'src/features/nlp/types/naturalLanguageAtomState';

const createNaturalLanguageAtomState = (
    naturalLanguage: string,
    displayMode: NaturalLanguageAtomState['displayMode'],
    code: string,
): NaturalLanguageAtomState => {
    // Confirmed
    if (naturalLanguage && code) {
        return {
            naturalLanguage,
            displayMode,
            atom: {
                status: 'confirmed',
                confirmedFormula: code,
            },
        };
    }

    if (naturalLanguage && !code) {
        return {
            naturalLanguage,
            displayMode: 'language',
            atom: {
                status: 'writing',
            },
        };
    }

    if (code && !naturalLanguage) {
        return {
            naturalLanguage: '',
            displayMode: 'formula',
            atom: {
                status: 'user_code',
                code,
            },
        };
    }

    if (displayMode === 'formula') {
        return {
            naturalLanguage: '',
            displayMode,
            atom: {
                status: 'user_code',
                code: '',
            },
        };
    } else {
        return {
            naturalLanguage: '',
            displayMode,
            atom: {
                status: 'writing',
            },
        };
    }
};

export const conditionModelFromIfThenLine = (ifThenLine: IfThenLine, parent: ConditionGroupModel): ConditionModel => {
    return new ConditionModel({
        id: ifThenLine.id,
        isSelfEnabled: ifThenLine.enabled,
        conditionState:
            ifThenLine.mode === 'formula'
                ? {
                      type: 'non-guided' as const,
                      state: createNaturalLanguageAtomState(
                          ifThenLine.naturalLanguage ?? '',
                          ifThenLine.fieldInView === 'nlp' ? 'language' : 'formula',
                          ifThenLine.formula ?? '',
                      ),
                  }
                : {
                      type: 'guided' as const,
                      operator: ifThenLine.operator,
                      lhs: createNaturalLanguageAtomState(
                          ifThenLine.lhs.naturalLanguage ?? '',
                          ifThenLine.lhs.fieldInView === 'nlp' ? 'language' : 'formula',
                          ifThenLine.lhs.formula ?? '',
                      ),
                      rhs: createNaturalLanguageAtomState(
                          ifThenLine.rhs.naturalLanguage ?? '',
                          ifThenLine.rhs.fieldInView === 'nlp' ? 'language' : 'formula',
                          ifThenLine.rhs.formula ?? '',
                      ),
                  },
        parent,
    });
};
