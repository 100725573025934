import { push } from 'redux-first-history';
import { injectable, inject } from 'src/features/ioc';
import type { LoginScreenService } from 'src/services/LoginScreenService';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class LoginScreenServiceImpl implements LoginScreenService {
    constructor(
        // We use a provider to inject here since there's a circular dependency between the store and the service
        @inject('StoreProvider') private readonly storeProvider: ReactBindings['StoreProvider'],
    ) {}

    showLoginScreen(url: string) {
        window.location.href = url;
    }

    async showLoggedOutScreen() {
        const store = await this.storeProvider();

        store.dispatch(push('/loggedout'));
    }
}
