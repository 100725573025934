import type { OrderEntryWidgetModel as OrderEntryWidgetModelContract } from 'src/contracts/workspace';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { OrderEntryWidgetModel } from 'src/models/OrderEntryWidgetModel';
import { updateWidget } from 'src/store/actions/widgetAndChannel';
import { userSelectedNewOrderEntrySymbol } from 'src/store/actions/widgets/orderEntry';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class OrderEntryWidgetModelImpl extends ReactiveInjectable implements OrderEntryWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }

    private get widget(): OrderEntryWidgetModelContract {
        return this.widgetData.widget as OrderEntryWidgetModelContract;
    }

    get symbol() {
        return this.widget.symbol ?? '';
    }

    changeSymbol(symbol: string) {
        this.store.dispatch(userSelectedNewOrderEntrySymbol(this.widgetData.tabId, symbol));
    }

    convertToSimpleVariant() {
        this.store.dispatch(
            updateWidget({
                tabId: this.widgetData.tabId,
                widgetUpdates: {
                    type: 'simple-order-entry',
                },
                tabUpdates: {
                    name: 'Trade',
                },
            }),
        );
    }
}
