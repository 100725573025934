import { IfThenFieldInView, IfThenLineMode } from '../../contracts/if-then';
import type { ApplicationMenuType } from '../types';
import { ApplicationMenu } from '../types';
import { Instant } from '@js-joda/core';
import type { OptionsObject, SnackbarMessage } from 'notistack';
import type { IfThenLineSettings } from 'src/features/legacyIfThen/model';
import type { AppAction } from 'src/store/actions';

export interface SymbolCacheEntry {
    symbol: string;
    lastUse: Instant;
}

export interface UIState {
    superuserAccessEnabledUntil?: Instant;
    defaultLineSettings: IfThenLineSettings;
    snackbarMessage?: {
        message: SnackbarMessage;
        options?: OptionsObject;
    };
    menus: {
        application: ApplicationMenuType;
    };
    symbolCache: SymbolCacheEntry[];
    alwaysOnTop: boolean;
    blockedWidgets: boolean;
}

export const initialState: UIState = {
    superuserAccessEnabledUntil: undefined,
    defaultLineSettings: {
        lineMode: IfThenLineMode.formula,
        fieldInView: IfThenFieldInView.nlp,
    },
    symbolCache: [],
    snackbarMessage: undefined,
    menus: {
        application: ApplicationMenu.loggedOutClosed,
    },
    alwaysOnTop: false,
    blockedWidgets: false,
};

export const ui = (state = initialState, action: AppAction): UIState => {
    switch (action.type) {
        case 'masq::access-token::set': {
            return { ...state, superuserAccessEnabledUntil: undefined };
        }
        case 'superuser::set-expiration': {
            return { ...state, superuserAccessEnabledUntil: action.superuserAccessEnabledUntil };
        }

        case 'symbol-cache::set': {
            return { ...state, symbolCache: action.symbolCache };
        }

        case 'default-line-settings::set': {
            return { ...state, defaultLineSettings: action.defaultLineSettings };
        }

        case 'snackbar::set': {
            return {
                ...state,
                snackbarMessage: {
                    message: action.message,
                    options: action.options,
                },
            };
        }

        case 'snackbar::clear': {
            return { ...state, snackbarMessage: undefined };
        }

        case 'menu::set-application-menu':
            return {
                ...state,
                menus: {
                    ...state.menus,
                    application: action.menu,
                },
            };

        case 'symbol-cache::add': {
            const newCache = [...state.symbolCache];
            const existingIdx = newCache.findIndex((x) => x.symbol === action.symbol);
            if (existingIdx !== -1) {
                newCache[existingIdx] = { ...newCache[existingIdx], lastUse: Instant.now() };
            } else {
                newCache.push({ symbol: action.symbol, lastUse: Instant.now() });
            }
            return { ...state, symbolCache: newCache };
        }

        case 'symbol-cache::remove': {
            return {
                ...state,
                symbolCache: state.symbolCache.filter((x) => x.symbol !== action.symbol),
            };
        }

        case 'app-always-on-top': {
            return { ...state, alwaysOnTop: action.alwaysOnTop };
        }

        case 'widgets::blocked': {
            return { ...state, blockedWidgets: action.blocked };
        }

        default:
            return state;
    }
};
