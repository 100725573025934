import type { ReactiveInjectable } from 'src/features/ioc';

export interface AlphaFilterFilter {
    id: string;
    name: string;
    categoryId: string;
}

export interface AlphaFilterFilterCategory {
    id: string;
    name: string;
}

export const APPLIED_FILTER_CHANGE_EVENT = 'applied-filter-change';

export type AppliedFilterChangeEvent = CustomEvent<void>;

export interface AlphaFilterAppliedFilterType {
    changeTarget: EventTarget;
    durationValue: string | number;
    filterId: string; // id of the filter that is applied
    id: string; // unique id for the applied filter
    minValue: number | string;
    maxValue: number | string;
    name: string;
    periodValue: string;
    periodOptions: { name: string; value: string }[];
    status: 'active' | 'blank' | 'paused';
}

export interface AlphaFilterModel extends ReactiveInjectable {
    filters: AlphaFilterFilter[];
    filterCategories: AlphaFilterFilterCategory[];
    appliedFilters: AlphaFilterAppliedFilterType[];
    addFilter(filter: AlphaFilterFilter): void;
    addFilterCategory(filterCategory: AlphaFilterAppliedFilterType | AlphaFilterFilter): void;
    addAppliedFilter(filter: AlphaFilterAppliedFilterType | AlphaFilterFilter): void;
    hoveredFilter: string | undefined;
    init(tabId: string): void;
    getFiltersInUseForFilterId(filterId: string): number;
    removeAppliedFilter(filterId: string): void;
}
