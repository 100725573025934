export interface FeatureFlagOptions<T> {
    /**
     * The key created in the LaunchDarkly interface to identify the feature flag.
     */
    key: string;
    /**
     * The default value to use if the feature flag is not found or the user is not in the targeting.
     */
    defaultValue: T;
}

export class FeatureFlag<T> {
    constructor(options: FeatureFlagOptions<T>) {
        this.#key = options.key;
        this.#defaultValue = options.defaultValue;
    }

    #defaultValue: T;

    get defaultValue(): T {
        return this.#defaultValue;
    }

    #key: string;

    get key(): string {
        return this.#key;
    }
}
