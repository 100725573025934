import { QueryObserverModel } from '.';
import { QueryObserverOptions, QueryObserverResult, QueryObserver } from '@tanstack/query-core';
import { inject, injectable } from 'src/features/ioc';
import { ReactBindings } from 'src/types/bindings';

@injectable()
export class QueryObserverModelImpl<T> implements QueryObserverModel<T> {
    constructor(@inject('QueryClient') private queryClient: ReactBindings['QueryClient']) {}

    #query!: QueryObserver<T>;

    #showLeading = true;

    init(options: QueryObserverOptions<T>, showLeading?: boolean) {
        this.#query = new QueryObserver(this.queryClient, options);
        this.#showLeading = showLeading ?? true;
    }

    setQueryOptions(options: QueryObserverOptions<T>) {
        this.#query.setOptions(options);
    }

    getCurrentResult() {
        return this.#query.getCurrentResult();
    }

    subscribe(callback: (result: QueryObserverResult<T>) => void) {
        const unsubscribe = this.#query.subscribe(callback);

        if (this.#showLeading) {
            callback(this.#query.getCurrentResult());
        }

        const disposable = {
            [Symbol.dispose]: () => unsubscribe(),
        };

        this.#queryDisposableStack.use(disposable);

        return disposable;
    }

    #queryDisposableStack = new DisposableStack();
    [Symbol.dispose]() {
        this.#queryDisposableStack?.dispose();
    }
}
