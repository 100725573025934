import { getNewsForTicker, getNewsSummaryForTicker } from '.';
import { type LookBackPeriod } from '../components/NewsSummary';
import { useQuery } from '@tanstack/react-query';
import { ONE_MINUTE } from 'src/lib/util/timeConstants';

export const getNewsQuery = (tickerSymbol: string, disabled = false) => ({
    queryKey: ['news', tickerSymbol],
    queryFn: () => getNewsForTicker(tickerSymbol),
    enabled: !disabled,
    refetchInterval: ONE_MINUTE * 5, // refetch every 5 minutes
});

export const useGetNewsQuery = (tickerSymbol: string, disabled = false) => {
    return useQuery(getNewsQuery(tickerSymbol, disabled));
};

export const useGetNewsSummaryQuery = (tickerSymbol: string, ...lookBackPeriod: LookBackPeriod) => {
    const [from, to] = lookBackPeriod;
    return useQuery({
        queryKey: ['news-summary', tickerSymbol],
        queryFn: () => getNewsSummaryForTicker(tickerSymbol, from, to),
        enabled: !!tickerSymbol,
        refetchInterval: ONE_MINUTE * 5, // refetch every 5 minutes
    });
};
