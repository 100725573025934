import { api, createInstance } from 'src/api';
import { appConfig } from 'src/lib/config';

export type NewsFeedItem = {
    author: string;
    body: string;
    id: number;
    url: string;
    created: string;
    title: string;
    type: 'news' | 'press_release';
    updated: string;
};
export type NewsSummaryResponse = {
    result: {
        abstract: string;
        summary: string;
    };
};

export const getNewsForTicker = async (ticker: string) => {
    const { data } = await api.get<NewsFeedItem[]>(`/news/${ticker}`);

    return data;
};

const cbApi = createInstance({ baseURL: appConfig.chatbotApi });

export const getNewsSummaryForTicker = async (ticker: string, from: string, to: string) => {
    const {
        data: { result },
    } = await cbApi.post<NewsSummaryResponse>(`/v1/summarize_news`, {
        request_type: 'summarize_news',
        symbol_or_search_string: ticker,
        query_type: 'symbol',
        from_timestamp: from,
        to_timestamp: to,
    });

    return result;
};
