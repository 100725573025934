import type { IndicatorFormulaViewModel, IndicatorImportRefViewModel } from '../contracts/dictionary-view-model';
import type { Strategy } from '../contracts/strategy';
import { StrategyType } from '../contracts/strategy';
import { type BespokeIndicatorImport, type IndicatorImportRef } from 'src/contracts/dictionary';

export async function getStrategyFromFormula(
    formula: string,
    naturalLanguage: string,
    imports: (BespokeIndicatorImport | IndicatorImportRef)[],
): Promise<Strategy<IndicatorImportRefViewModel> | null> {
    const plan: IndicatorFormulaViewModel = { formula, imports };

    const strategy: Strategy<IndicatorImportRefViewModel> = {
        name: naturalLanguage,
        description: null,
        defaultUniverseId: null,
        defaultColumnTemplateId: null,
        isTemplate: false,
        type: StrategyType.formula,
        plan,
    };

    return strategy;
}
