import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { MessagesWidgetModel } from 'src/models/MessagesWidgetModel';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import type { MessagesWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class MessagesWidgetModelImpl extends ReactiveInjectable implements MessagesWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    private get widget() {
        return this.widgetData.widget as MessagesWidgetViewModel;
    }

    get symbolFilter() {
        return this.widget.symbolFilter;
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }

    userSelectedSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }
}
