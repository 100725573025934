import type { SlidingWindow } from '@thinkalpha/language-services';
import type { Interval, SessionType } from 'src/contracts/timeframe';
import type {
    ChartPane,
    ChartSettings,
    KeyboardCommand,
    TimeSeriesWidgetModel as TimeSeriesWidgetModelContract,
} from 'src/contracts/workspace';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { TimeSeriesWidgetModel, TimeSeriesWidgetState } from 'src/models/TimeSeriesWidgetModel';
import { updateWidget } from 'src/store/actions/widgetAndChannel';
import { changeTimeSeriesSymbol } from 'src/store/actions/widgets/timeSeries';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class TimeSeriesWidgetModelImpl extends ReactiveInjectable implements TimeSeriesWidgetModel {
    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') private store: ReactBindings['Store'],
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    init(tabId: string) {
        this.widgetData.init(tabId);
    }

    changeSymbol(newSymbol: string) {
        const { tabId } = this.widgetData;
        return this.store.dispatch(changeTimeSeriesSymbol(tabId, newSymbol));
    }

    update(updates: Partial<TimeSeriesWidgetState>) {
        const { tabId } = this.widgetData;
        this.store.dispatch(
            updateWidget({
                tabId,
                widgetUpdates: updates,
            }),
        );
    }

    private get widget(): TimeSeriesWidgetModelContract {
        return this.widgetData.widget as TimeSeriesWidgetModelContract;
    }

    get channelId(): string | null {
        return this.widgetData.channelId;
    }

    get type(): 'time-series' {
        return 'time-series';
    }

    get barLength(): SlidingWindow {
        return this.widget.barLength;
    }

    get interval(): Interval {
        return this.widget.interval;
    }

    get symbol(): string {
        return this.widget.symbol;
    }

    get panes(): ChartPane[] {
        return this.widget.panes;
    }

    get keyboardCommands(): KeyboardCommand[] | undefined {
        return this.widget.keyboardCommands;
    }

    get session(): SessionType {
        return this.widget.session;
    }

    get plotBandColors(): Record<string, string> {
        return this.widget.plotBandColors;
    }

    get chartSettings(): ChartSettings | undefined {
        return this.widget.chartSettings;
    }
}
