import type { ComparisonOperator } from '@thinkalpha/language-services';
import type { NaturalLanguageAtomState } from 'src/features/nlp/types/naturalLanguageAtomState';

export const CONDITION_CHANGE_EVENT = 'search-change';

export type ConditionChangeEvent = CustomEvent<void>;

export interface AggregateStats {
    childCount: number;
    unsubmittedCount: number;
    pendingNlpCount: number;
    errorCount: number;
}

export interface ConditionStateGuided {
    type: 'guided';
    lhs: NaturalLanguageAtomState;
    rhs: NaturalLanguageAtomState;
    operator: ComparisonOperator | null;
}
export interface ConditionStateNonGuided {
    type: 'non-guided';
    state: NaturalLanguageAtomState;
}

export type ConditionState = ConditionStateGuided | ConditionStateNonGuided;

export interface BaseSearchNode {
    changeTarget: EventTarget;
    delete(): void;
    id: string;
    isDirty: boolean;
    isEnabledInSearch: boolean;
    isSelfEnabled: boolean;
    isUnsaved: boolean;
    isUnsubmitted: boolean;
    setEnabled(enabled: boolean): void;
    setIsDirty(isDirty: boolean): void;
    setIsUnsaved(isUnsaved: boolean): void;
    setParent(parent: SearchConditionGroup): void;
}

export type SearchConditionErrorLocation = 'lhs' | 'rhs' | 'operator' | 'line';

export interface SearchCondition extends BaseSearchNode {
    conditionState: ConditionState;
    errors: SearchConditionErrorLocation[] | null;
    needsAttention: boolean;
    isGuidedMode: boolean;
    isPendingNlp: boolean;
    parent: SearchConditionGroup;
    setGuidedMode(isGuidedMode: boolean): void;
    updateConditionState(state: NaturalLanguageAtomState): void;
}

export interface SearchConditionGroup extends BaseSearchNode {
    addChild(child: SearchNode, index?: 'start' | 'end' | number): void;
    booleanOperator: 'and' | 'or';
    children: SearchNode[];
    collapse(): void;
    collapseAll(): void;
    expand(): void;
    expandAll(): void;
    findChildById(id: string): SearchNode | null;
    isExpanded: boolean;
    label: string | undefined;
    labelIndex: number;
    nestedChildStats: AggregateStats;
    parent: SearchConditionGroup | false;
    removeChild(child: SearchNode): void;
    replaceChild(oldChild: SearchNode, newChild: SearchNode): void;
    setBooleanalabiity(conditionality: 'and' | 'or'): void;
    setLabel(label: string): void;
    toggleBooleanOperator(): void;
}

export type SearchNode = SearchCondition | SearchConditionGroup;
