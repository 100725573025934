import { type AppAction } from '../actions';
import type { ThinkAlphaTourGuideStepsWithWidgetContext } from 'src/features/onboarding/types';

export interface TourGuideState {
    isTourActive: boolean;
    activeTourContainer: string | null;
    tourGuideSteps: ThinkAlphaTourGuideStepsWithWidgetContext;
}

const initialState: TourGuideState = {
    isTourActive: false,
    activeTourContainer: null,
    tourGuideSteps: {} as ThinkAlphaTourGuideStepsWithWidgetContext,
};

export const onboarding = (state = initialState, action: AppAction): TourGuideState => {
    switch (action.type) {
        case 'tour-guide::updateTourSteps':
            return {
                ...state,
                tourGuideSteps: {
                    ...state.tourGuideSteps,
                    [action.payload.widgetType]: {
                        ...state.tourGuideSteps[action.payload.widgetType],
                        [action.payload.containerId]: action.payload.updatedSteps,
                    },
                },
            };
        case 'tour-guide::setActiveTourContainer':
            return {
                ...state,
                activeTourContainer: action.payload,
            };
        case 'tour-guide::setIsTourActive':
            return {
                ...state,
                isTourActive: action.payload,
            };
    }
    return state;
};
