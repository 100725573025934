import type { KnownAstNode, ParserError, ParserResult } from '@thinkalpha/language-services';
import { iterateAst } from '@thinkalpha/language-services';
import { inject, injectable } from 'src/features/ioc';
import type { CodeDocumentError, CodeDocumentModel, CodeDocumentParseOptions } from 'src/models/CodeDocumentModel';
import { type ReactBindings } from 'src/types/bindings';

const documentErrorFromParserError = (err: ParserError, node?: KnownAstNode): CodeDocumentError => {
    return {
        start: err.range?.start ?? node?.ranges.node.start ?? 0,
        end: err.range?.end ?? node?.ranges.node.end ?? 0,
        message: err.text,
    };
};

@injectable()
export class CodeDocumentModelImpl implements CodeDocumentModel {
    /**
     * The document text.
     */
    #code: string = '';
    /**
     * Options that are passed to the parser.
     */
    #parseOptions: CodeDocumentParseOptions = {};
    /**
     * AST of the code. `await` the result of `updateCode` or `updateParseOptions` to ensure this is the latest.
     */
    #parserResult: ParserResult | null = null;
    /**
     * Whether the code is valid.
     */
    #isValid: boolean = false;
    /**
     * Aggregated errors from the parser.
     */
    #errors: CodeDocumentError[] = [];

    constructor(@inject('FormulaService') private formulaService: ReactBindings['FormulaService']) {}

    async #parse() {
        const result = await this.formulaService.parse(
            this.#code,
            this.#parseOptions.analyzers ?? [],
            this.#parseOptions.equalsMode,
            this.#parseOptions.dataTypeRequired,
        );

        let isValid = true;
        const errors = [...result.errors.map((err) => documentErrorFromParserError(err))];

        if (!result.root) isValid = false;
        else if (!result.valid) isValid = false;
        else if (!result.root.dataType) isValid = false;

        for (const node of iterateAst(result.root)) {
            errors.push(...node.errors.map((err) => documentErrorFromParserError(err, node)));
            if (!node.valid) isValid = false;
        }

        this.#isValid = isValid;
        this.#parserResult = result;
        this.#errors = errors;
    }

    get code(): string {
        return this.#code;
    }

    get errors(): CodeDocumentError[] {
        return this.#errors;
    }

    get isValid(): boolean {
        return this.#isValid;
    }

    get parserResult(): ParserResult | null {
        return this.#parserResult;
    }

    async updateParseOptions(options: CodeDocumentParseOptions): Promise<void> {
        this.#parseOptions = options;

        await this.#parse();
    }

    async updateCode(code: string): Promise<void> {
        // TODO: This is broken because we must re-parse the code if the imports change.
        // If the code hasn't changed and we have a result, we re-use the last result.
        //if (code === this.#code) return Promise.resolve();

        this.#code = code;

        await this.#parse();
    }
}
