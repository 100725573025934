import type { IfThenFieldInView, IfThenGroup, IfThenLine, IfThenLineMode, IfThenPart } from 'src/contracts/if-then';

export const DRAG_MODEL_TYPE = 'condition';
export const DEFAULT_LINE_SETTINGS_CACHE_KEY = '_default_line_settings';

export interface DragModel {
    model: IfThenLine | IfThenGroup;
    type: string;
    onDragLeave?: (item: any) => void;
    onDragDone?: (item: any) => void;
    height?: number;
    dropZoneId?: string;
    depth: number;
    clearDragPlaceholder?: () => void;
}

export type DropPosition = 'top' | 'bottom';

export interface DragPreview {
    lineId: string;
    position: DropPosition;
    height: number;
}

export interface DragProps {
    onDragLeave: (id: string) => void;
    onDragDone: (id: string) => void;
    onDragStart: (preview: DragPreview) => void;
    clearDragPlaceholder: () => void;
}

export function isIfThenGroup(line: IfThenPart): line is IfThenGroup {
    return (line as IfThenGroup).type === 'group';
}

export function isIfThenLine(line: IfThenPart): line is IfThenLine {
    return (line as IfThenLine).type === 'line';
}

export interface IfThenLineSettings {
    lineMode: IfThenLineMode;
    fieldInView: IfThenFieldInView;
}

/**
 * Whether the user has filled in no, some, or all fields on a single line
 */
export enum FieldsFilledStatus {
    empty = 'empty',
    complete = 'complete',
    incomplete = 'incomplete',
}
