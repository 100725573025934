import { ConditionGroupModel } from '.';
import { conditionModelFromIfThenLine } from '../ConditionModel/conditionModelFromIfThenLine';
import type { IfThenGroup } from 'src/contracts/if-then';

export const conditionGroupModelFromIfThenGroup = (
    ifThenGroup: IfThenGroup,
    parent: ConditionGroupModel,
    mergeRoots: boolean = false,
): ConditionGroupModel => {
    const newGroupModel = mergeRoots
        ? parent
        : new ConditionGroupModel({
              id: ifThenGroup.id,
              booleanOperator: ifThenGroup.operator === 'and' ? 'and' : 'or',
              isSelfEnabled: ifThenGroup.enabled,
              isExpanded: !ifThenGroup.collapsed,
              label: ifThenGroup.name ?? '',
              children: [],
              parent,
          });

    const children = ifThenGroup.lines.map((line) => {
        if (line.type === 'group') {
            return conditionGroupModelFromIfThenGroup(line, newGroupModel);
        }

        return conditionModelFromIfThenLine(line, newGroupModel);
    });

    children.forEach((child) => {
        newGroupModel.addChild(child);
    });

    return newGroupModel;
};
