import type { IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import type { ResearchStrategy } from 'src/lib/strategyModel';
import type { IfThenBuilderWidgetModel } from 'src/models/IfThenBuilderWidgetModel';
import { userRanChanges } from 'src/store/actions/widgets/searchAlpha';
import type { ReactBindings } from 'src/types/bindings';

@injectable()
export class IfThenBuilderWidgetModelImpl extends ReactiveInjectable implements IfThenBuilderWidgetModel {
    constructor(@inject('Store') @reacts private store: ReactBindings['Store']) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    #tabId!: string;

    init(tabId: string) {
        this.#tabId = tabId;
    }

    runChanges(strategy: ResearchStrategy<IndicatorImportViewModel>) {
        this.store.dispatch(userRanChanges(this.#tabId, strategy));
    }
}
