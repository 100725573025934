import type { SymbolCacheEntry } from '../reducers/ui';
import type { ApplicationMenuType } from '../types';
import type { OptionsObject, SnackbarMessage } from 'notistack';
import type { IfThenLineSettings } from 'src/features/legacyIfThen/model';

export type SetDefaultLineSettingsAction = {
    type: 'default-line-settings::set';
    defaultLineSettings: IfThenLineSettings;
};

export const setDefaultLineSettings = (defaultLineSettings: IfThenLineSettings): SetDefaultLineSettingsAction => ({
    type: 'default-line-settings::set',
    defaultLineSettings,
});

export type SetSnackbarAction = { type: 'snackbar::set'; message: SnackbarMessage; options?: OptionsObject };

export const setSnackbar = (message: SnackbarMessage, options?: OptionsObject): SetSnackbarAction => ({
    type: 'snackbar::set',
    message,
    options,
});

export type SetSymbolCacheAction = { type: 'symbol-cache::set'; symbolCache: SymbolCacheEntry[] };
export const setSymbolCache = (symbolCache: SymbolCacheEntry[]): SetSymbolCacheAction => ({
    type: 'symbol-cache::set',
    symbolCache,
});

export type AddToSymbolCacheAction = { type: 'symbol-cache::add'; symbol: string };
export const addToSymbolCache = (symbol: string): AddToSymbolCacheAction => ({
    type: 'symbol-cache::add',
    symbol,
});

export type RemoveFromSymbolCacheAction = { type: 'symbol-cache::remove'; symbol: string };
export const removeFromSymbolCache = (symbol: string): RemoveFromSymbolCacheAction => ({
    type: 'symbol-cache::remove',
    symbol,
});

export type ClearSnackbarAction = { type: 'snackbar::clear' };

export const clearSnackbar = (): ClearSnackbarAction => ({
    type: 'snackbar::clear',
});

export const setApplicationMenu = (menu: ApplicationMenuType) => ({
    type: 'menu::set-application-menu' as const,
    menu,
});

export type SetApplicationMenuAction = ReturnType<typeof setApplicationMenu>;

export type SetNewsTicker = { type: 'news::setTicker'; ticker: string };

export const setNewsTicker = (ticker: string): SetNewsTicker => ({
    type: 'news::setTicker',
    ticker,
});

export type SetWidgetsBlocked = { type: 'widgets::blocked'; blocked: boolean };
export const setWidgetsBlocked = (blocked: boolean): SetWidgetsBlocked => ({
    type: 'widgets::blocked',
    blocked,
});

export type UIAction =
    | SetDefaultLineSettingsAction
    | SetSnackbarAction
    | ClearSnackbarAction
    | SetApplicationMenuAction
    | SetSymbolCacheAction
    | AddToSymbolCacheAction
    | RemoveFromSymbolCacheAction
    | SetWidgetsBlocked;
