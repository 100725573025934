import { AppliedFilterModel } from './AppliedFilterModel';
import { APPLIED_FILTERS_CHILD_LISTENERS } from './weakMaps';
import { container } from 'src/StaticContainer';
import { ReactiveInjectable, reacts, injectable } from 'src/features/ioc';
import {
    APPLIED_FILTER_CHANGE_EVENT,
    type AlphaFilterAppliedFilterType,
    type AlphaFilterFilter,
    type AlphaFilterFilterCategory,
    type AlphaFilterModel,
} from 'src/models/AlphaFilterModel';
import { v4 } from 'uuid';

const log = container.get('Logger').getSubLogger({ name: 'AlphaFilterModel' });

export interface AlphaFilterModelState {
    filters: AlphaFilterFilter[];
    filterCategories: AlphaFilterFilterCategory[];
    appliedFilters: AlphaFilterAppliedFilterType[];
    hoveredFilter: string | undefined;
    inUseFilterCount: Record<string, number>;
}

@injectable()
export class AlphaFilterModelImpl extends ReactiveInjectable implements AlphaFilterModel {
    #state: AlphaFilterModelState = {
        filters: [],
        filterCategories: [],
        appliedFilters: [],
        hoveredFilter: undefined,
        inUseFilterCount: {},
    };

    addFilter(filter: AlphaFilterFilter) {
        this.state = {
            ...this.state,
            filters: [...this.#state.filters, filter],
        };
    }

    addFilterCategory(filterCategory: AlphaFilterFilterCategory) {
        this.state = {
            ...this.state,
            filterCategories: [...this.#state.filterCategories, filterCategory],
        };
    }

    #calculateFiltersInUse() {
        const inUseFilterCount: Record<string, number> = {};
        this.#state.appliedFilters.forEach((appliedFilter) => {
            inUseFilterCount[appliedFilter.filterId] = (inUseFilterCount[appliedFilter.filterId] || 0) + 1;
        });
        this.state = {
            ...this.#state,
            inUseFilterCount,
        };
    }

    #onAppliedFilterChange() {
        this.rerender();
    }

    addAppliedFilter(filterToApply: AlphaFilterAppliedFilterType | AlphaFilterFilter) {
        if (!(filterToApply instanceof AppliedFilterModel)) {
            const newAppliedFilter: AlphaFilterAppliedFilterType = {
                changeTarget: new EventTarget(),
                id: v4(),
                filterId: filterToApply.id,
                name: filterToApply.name,
                status: 'blank',
                minValue: '',
                maxValue: '',
                periodValue: '',
                durationValue: '',
                periodOptions: [],
            };

            filterToApply = new AppliedFilterModel(newAppliedFilter);
        }

        this.appliedFilters = [...this.#state.appliedFilters, filterToApply];

        this.#calculateFiltersInUse();

        const listener = this.#onAppliedFilterChange.bind(this);
        APPLIED_FILTERS_CHILD_LISTENERS.set(filterToApply, listener);
        filterToApply.changeTarget.addEventListener(APPLIED_FILTER_CHANGE_EVENT, listener);

        return;
    }

    removeAppliedFilter(filterId: string) {
        const filterToRemove = this.#state.appliedFilters.find((appliedFilter) => appliedFilter.id === filterId);
        if (!filterToRemove) {
            return;
        }

        this.appliedFilters = this.#state.appliedFilters.filter(
            (appliedFilter) => appliedFilter.id !== filterToRemove.id,
        );

        this.#calculateFiltersInUse();

        const listener = APPLIED_FILTERS_CHILD_LISTENERS.get(filterToRemove);
        if (listener) {
            filterToRemove.changeTarget.removeEventListener(APPLIED_FILTER_CHANGE_EVENT, listener);
        }
    }

    get appliedFilters() {
        return this.#state.appliedFilters;
    }

    set appliedFilters(appliedFilters: AlphaFilterAppliedFilterType[]) {
        this.state = {
            ...this.state,
            appliedFilters,
        };
    }

    get hoveredFilter() {
        return this.#state.hoveredFilter;
    }

    set hoveredFilter(filterId: string | undefined) {
        this.state = {
            ...this.#state,
            hoveredFilter: filterId,
        };
    }

    constructor() {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);
    }

    getFiltersInUseForFilterId(filterId: string) {
        return this.#state.inUseFilterCount[filterId] || 0;
    }

    get filters() {
        return this.#state.filters;
    }

    get filterCategories() {
        return this.#state.filterCategories;
    }

    init(_tabId: string) {
        // TODO: query for the filters from the API

        this.disposableStack.use({
            [Symbol.dispose]: () => {
                log.debug({ message: 'Disposing alpha filter observables' });
            },
        });
    }

    @reacts set state(state: AlphaFilterModelState) {
        this.#state = state;
    }
}
